import React from "react"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../../links/internal"
import { StyledLink } from "./common-styles"

export const CONSENT_BANNER_ID = "cookie-consent-banner"
export const CONSENT_BANNER_CLASSNAME = "cookie-consent-banner"
export const INFO_ICON_ALTERNATIVE_TEXT = "info-icon"
export const CONSENT_BANNER_TITLE =
  "We use cookies to improve your experience and our services."
export const CONSENT_BANNER_DESCRIPTION = (
  <span>
    By clicking Agree you accept our{" "}
    <StyledLink style={{ color: "#c4b180" }} to={GLOBAL_APPLICATION_INTERNAL_LINKS.PRIVACY_POLICY}>
      Privacy
    </StyledLink>{" "}
    and{" "}
    <StyledLink style={{ color: "#c4b180" }} to={GLOBAL_APPLICATION_INTERNAL_LINKS.COOKIE_POLICY}>
      Cookie
    </StyledLink>{" "}
    policies.
  </span>
)
export const SHOW_DETAILS_TEXT = "Show details"
export const STORAGE_KEY = "impressit"

export const GOOGLE_TAG_FLAGS = {
  AD_STORAGE: "ad_storage",
  ANALYTICS_STORAGE: "analytics_storage",
  AD_USER_DATA: "ad_user_data",
  AD_PERSONALIZATION: "ad_personalization",
  FUNCTIONALITY_STORAGE: "functionality_storage",
  PERSONALIZATION_STORAGE: "personalization_storage",
  SECURITY_STORAGE: "security_storage",
}

export const CONSENT_MODE_STATUSES = {
  GRANTED: "granted",
  DENIED: "denied",
}

export const CONSENT_MODE_CONFIGURATIONS = {
  ACCEPT_ALL: {
    ad_storage: CONSENT_MODE_STATUSES.GRANTED,
    analytics_storage: CONSENT_MODE_STATUSES.GRANTED,
    ad_user_data: CONSENT_MODE_STATUSES.GRANTED,
    ad_personalization: CONSENT_MODE_STATUSES.GRANTED,
    functionality_storage: CONSENT_MODE_STATUSES.GRANTED,
    personalization_storage: CONSENT_MODE_STATUSES.GRANTED,
    security_storage: CONSENT_MODE_STATUSES.GRANTED,
  },
  DEFAULT: {
    ad_storage: CONSENT_MODE_STATUSES.GRANTED,
    analytics_storage: CONSENT_MODE_STATUSES.GRANTED,
    ad_user_data: CONSENT_MODE_STATUSES.GRANTED,
    ad_personalization: CONSENT_MODE_STATUSES.GRANTED,
    functionality_storage: CONSENT_MODE_STATUSES.GRANTED,
    personalization_storage: CONSENT_MODE_STATUSES.GRANTED,
    security_storage: CONSENT_MODE_STATUSES.GRANTED,
  },
  REJECT_ALL: {
    ad_storage: CONSENT_MODE_STATUSES.DENIED,
    analytics_storage: CONSENT_MODE_STATUSES.DENIED,
    ad_user_data: CONSENT_MODE_STATUSES.DENIED,
    ad_personalization: CONSENT_MODE_STATUSES.DENIED,
    functionality_storage: CONSENT_MODE_STATUSES.GRANTED,
    personalization_storage: CONSENT_MODE_STATUSES.DENIED,
    security_storage: CONSENT_MODE_STATUSES.GRANTED,
  },
}

export const ACCEPT_ALL_CONSENT_MODE_CONFIGURATION = {
  ad_storage: "granted",
  analytics_storage: "granted",
  ad_user_data: "granted",
  ad_personalization: "granted",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
}

export const TOGGLE_BUTTONS_IDS = {
  NECESSARY: "consent-necessary",
  ANALYTICS: "consent-analytics",
  MARKETING: "consent-marketing",
  PREFERENCES: "consent-preferences",
  PARTNERS: "consent-partners",
}

export const TOGGLE_BUTTONS_TEXTS = {
  NECESSARY: "Necessary",
  ANALYTICS: "Analytics",
  MARKETING: "Marketing",
  PREFERENCES: "Preferences",
  PARTNERS: "Partners",
}

export const ACTION_BUTTONS_IDS = {
  ACCEPT_ALL: "cookie-consent-btn-accept-all",
  ALLOW_SELECTION: "cookie-consent-btn-accept-some",
  REJECT_ALL: "cookie-consent-btn-reject-all",
}

export const ACTION_BUTTONS_TEXTS = {
  ACCEPT_ALL: "Allow all",
  ALLOW_SELECTION: "Allow selection",
  REJECT_ALL: "Reject all",
}

export const DESKTOP_TOGGLE_STYLES = {
  display: "flex",
  "align-items": "center",
  gap: "10px",
  cursor: "pointer",
}

export const TABLET_TOGGLE_STYLES = {
  display: "flex",
  "align-items": "center",
  gap: "10px",
  "flex-direction": "column-reverse",
}

export const MOBILE_TOGGLE_STYLES = {
  display: "flex",
  "align-items": "center",
  "flex-direction": "row-reverse",
  "justify-content": "space-between",
}
