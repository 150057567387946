import React, { useState } from "react"

const ToggleButton = ({ id, text, onToggle, toggleStyles, isNecessary}) => {
  const [isActive, setActive] = useState(true);

  const handleChange = () => {
    if (!isNecessary) {
      setActive(!isActive)
      onToggle();
    }
  };

  return (
    <label style={toggleStyles}>
      <input
        id={id}
        class="toggle-checkbox"
        onChange={handleChange}
        type="checkbox"
        checked={isActive}
        disabled={isNecessary}
      />
      <div class="toggle-switch" />
      <span class="toggle-label">{text}</span>
    </label>
  )
}

export default ToggleButton
