import React, { useContext } from "react"
import useWindowDimensions from "@hooks/useWindowDimensions"
import useCookie from "react-use-cookie"
import { getFromLocalStorage } from "../../../helpers"
import { CookieContext } from "./CookieContext"
import {
  MobileConsentBanner,
  TabletConsentBanner,
  DesktopConsentBanner,
} from "./components"
import { STORAGE_KEY } from "./constants"
import "./styles.scss"
import { ConsentContext } from "../../../contexts/ConsentContext"


const ConsentBanner = () => {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions()
  const [_, setUserToken] = useCookie("impressit")
  const { isOpenToEdit } = useContext(ConsentContext);

  return (
    (!getFromLocalStorage(STORAGE_KEY) || isOpenToEdit) && (
      <CookieContext.Provider value={{ setUserToken }}>
        {isDesktop && <DesktopConsentBanner />}
        {isTablet && <TabletConsentBanner />}
        {isMobile && <MobileConsentBanner />}
      </CookieContext.Provider>
    )
  )
}

export default ConsentBanner
