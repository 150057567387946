/*
    Overview: Consent mode parameters

    Setting Name            Used by Google    Description
    ad_storage              Yes               Enables storage (such as cookies) related to advertising
    analytics_storage       Yes               Enables storage (such as cookies) related to analytics e.g. visit duration
    ad_user_data            Yes               Whether Google’s services can use user data for building advertising audiences
    ad_personalization      Yes               Whether Google’s services can use the data for remarketing
    functionality_storage   No                Enables storage that supports the functionality of the website or app e.g. language settings
    personalization_storage No                Enables storage related to personalization e.g. video recommendations
    security_storage        No                Enables storage related to security such as authentication functionality, fraud prevention, and other user protection

*/

import React from "react"
import { setToLocalStorage } from "../../../../helpers"
import {
  CONSENT_BANNER_ID,
  CONSENT_MODE_CONFIGURATIONS,
  CONSENT_MODE_STATUSES,
  STORAGE_KEY,
} from "../constants"

const consentActions = {
  createCookies: (consent) => document.cookie = `consent=${JSON.stringify(consent)}; max-age=31536000; path=/`,
  updateConsent: (consent) => window.gtag("consent", "update", consent),
  saveConsent: (consent) => setToLocalStorage(STORAGE_KEY, JSON.stringify(consent)),
  closeEditMode: () => setToLocalStorage("consent_edit", false),
  hideBanner: () => document.getElementById(CONSENT_BANNER_ID).style.display = "none"
}

const securityChecks = {
  isTrackAllowed: () => {
    return navigator.doNotTrack == "1" || window.doNotTrack == "1"
  },

  hasGlobalPrivacyControl: () => {
    return navigator.globalPrivacyControl || window.globalPrivacyControl
  },
}

export const withConfig = WrappedComponent => {
  const config = {
    acceptAll: () => {
      consentActions.updateConsent(CONSENT_MODE_CONFIGURATIONS.ACCEPT_ALL);
      consentActions.saveConsent(CONSENT_MODE_CONFIGURATIONS.ACCEPT_ALL);
      consentActions.createCookies(CONSENT_MODE_CONFIGURATIONS.ACCEPT_ALL);
      consentActions.closeEditMode();
      consentActions.hideBanner();
    },

    collect: selection => {
      consentActions.updateConsent(selection);
      consentActions.saveConsent(selection);
      consentActions.createCookies(selection);
      consentActions.closeEditMode();
      consentActions.hideBanner();
    },

    findSelectedOptions: (collectionOptions, ...keys) => {
      collectionOptions(prevState => {
        const newState = { ...prevState }
        const toggleMap = {
          ad_storage: !securityChecks.isTrackAllowed(),
          analytics_storage: !securityChecks.isTrackAllowed(),
          ad_user_data: !securityChecks.isTrackAllowed(),
          ad_personalization: !securityChecks.hasGlobalPrivacyControl(),
        }
        keys.forEach(key => {
          const shouldGrant =
            toggleMap[key] !== undefined ? toggleMap[key] : true
          newState[key] = shouldGrant
            ? prevState[key] === CONSENT_MODE_STATUSES.GRANTED
              ? CONSENT_MODE_STATUSES.DENIED
              : CONSENT_MODE_STATUSES.GRANTED
            : CONSENT_MODE_STATUSES.DENIED
        })
        return newState
      })
    },

    rejectAll: () => {
      consentActions.updateConsent(CONSENT_MODE_CONFIGURATIONS.REJECT_ALL);
      consentActions.saveConsent(CONSENT_MODE_CONFIGURATIONS.REJECT_ALL);
      consentActions.createCookies(CONSENT_MODE_CONFIGURATIONS.REJECT_ALL);
      consentActions.closeEditMode();
      consentActions.hideBanner();
    },
  }

  return props => <WrappedComponent config={config} {...props} />
}
