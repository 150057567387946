import styled from "styled-components"

export const StyledMobileConsentBannerWrapper = styled.div`
  max-height: 90vh;
  overflow: scroll;
  position: fixed;
  padding: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background: var(--light-purple);
  width: 100%;
  z-index: 15;
`

export const StyledIconContainer = styled.div`
  border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
  min-height: 3.625rem;
`;

export const StyledTextContainer = styled.div`
  border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
`

export const StyledTextContainerTitle = styled.p`
  font-weight: bold;
`

export const StyledTextContainerDescription = styled.p``

export const StyledConsentOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  gap: 25px;
  border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
  margin-bottom: 1.3rem;
`

export const StyledConsentBannerFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

export const StyledShowDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 10px;
`;
