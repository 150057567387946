import styled from "styled-components"

export const StyledDesktopCookieBannerWrapper = styled.div`
  height: auto;
  position: fixed;
  padding: 24px;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background: var(--light-purple);
  width: 100%;
  z-index: 15;
`

export const StyledDesktopCookieBannerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const StyledTextContainer = styled.div`
  flex: 0.65;
`

export const StyledTextContainerTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`

export const StyledTextContainerDescription = styled.p``

export const StyledConsentOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  width: calc(100% - 9rem);
`
export const StyledDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const StyledActionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 0.18;
`
export const StyledShowDetails = styled.span`
  color: var(--main-white);
  display: flex;
  align-items: center;

  &::after {
    padding-left: 10px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14'%3E%3Cpath d='M1 13L7 7L1 1' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
    transition: all 0.3s;
  }

  &:hover {
    color: var(--main-gold);
    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14'%3E%3Cpath d='M1 13L7 7L1 1' stroke='%23C4B180' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
`;
