import React, { createContext, useEffect, useState } from "react"
import { getFromLocalStorage, setToLocalStorage } from "../../helpers"

export const ConsentContext = createContext()

const ConsentContextProvider = ({ children }) => {
  const [currentConsentState, setCurrentConsentState] = useState(
    getFromLocalStorage("consent_state") || "Denied"
  )
  const [consentCreationDate, setConsentCreationDate] = useState(
    getFromLocalStorage("consent_creation_date") || JSON.stringify(String(new Date()))
  )
  const [isOpenToEdit, setOpenForEdit] = useState(false)

  const collectConsentData = (key) => {
    setCurrentConsentState(key)
    setConsentCreationDate(String(new Date()))
    setOpenForEdit(false)
  }

  useEffect(() => {
    setToLocalStorage("consent_state", currentConsentState)
    setToLocalStorage("consent_creation_date", consentCreationDate)
  }, [currentConsentState])

  return (
    <ConsentContext.Provider
      value={{
        currentConsentState,
        setCurrentConsentState,
        consentCreationDate,
        setConsentCreationDate,
        isOpenToEdit,
        setOpenForEdit,
        collectConsentData
      }}
    >
      {children}
    </ConsentContext.Provider>
  )
}

export default ConsentContextProvider
