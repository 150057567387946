import styled from "styled-components";

export const StyledTabletConsentBannerWrapper = styled.div`
  height: auto;
  position: fixed;
  padding: 12px;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background: var(--light-purple);
  width: 100%;
  z-index: 15;
`

export const StyledTabletConsentBannerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledIconContainer = styled.div`
    border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
    min-height: 4.625rem;
`;

export const StyledTextContainer = styled.div`
    border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;

export const StyledTextContainerTitle = styled.p`
  font-weight: bold;
`

export const StyledTextContainerDescription = styled.p``

export const StyledConsentOptionsContainer = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 0.0625rem solid rgba(255,255,255,0.2);
`

export const StyledConsentOptionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1.5rem 1rem;
    border-right: 0.0625rem solid rgba(255,255,255,0.2);
    flex-direction: column-reverse;
    flex: 0.2;
`;

export const StyledShowDetailsWrapper = styled.div`
    width: 97%;
    padding: 1.3rem 0;
`;

export const StyledConsentBannerFooter = styled.div``;

export const StyledShowDetailsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
`;

export const StyledActionsButtonContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    padding-top: 1.3rem;
`;
