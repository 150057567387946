import { ReactSVG } from "react-svg";
import { Link } from "gatsby"
import styled from "styled-components";

export const StyledActionButton = styled.button`
  padding: 1em;
  margin-bottom: 0.5em;
  border: ${({ isAllowButton }) => isAllowButton ? "0.0625rem solid rgba(196, 177, 128, 0.6)" : "0.0625rem solid rgba(255, 255, 255, 0.3)"};
  background: ${({ isAllowButton }) => isAllowButton ? "rgba(196, 177, 128, 0.3)" : "transparent"};
  cursor: pointer;
  color: #ffffff;
  font-size: 1rem;

  &:hover {
    border: 0.06rem solid #c4b180 !important;
    color: white;
  }

  &:active {
    border: 0.06rem solid white;
  }
`

export const StyledInfoIcon = styled(ReactSVG)`
  align-self: center;
  margin: 0 1.25rem;

  & > svg {
    width: 3.1875rem;
    height: 4.1875rem;
    margin: 0 auto;
  }
`

export const StyledShowDetails = styled.span`
  color: var(--main-white);
  display: flex;
  align-items: center;
  cursor: pointer;

  &::after {
    padding-left: 10px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14'%3E%3Cpath d='M1 13L7 7L1 1' stroke='%23FFFFFF' stroke-width='2'/%3E%3C/svg%3E");
    transition: all 0.3s;
  }

  &:hover {
    color: var(--main-gold);
    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14'%3E%3Cpath d='M1 13L7 7L1 1' stroke='%23C4B180' stroke-width='2'/%3E%3C/svg%3E");
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;