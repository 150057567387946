import styled from "styled-components"
import { ReactSVG } from "react-svg"

export const StyledArrowUp = styled(ReactSVG)`
  position: fixed;
  bottom: 3.1rem;
  right: 3.1rem;
  width: 3rem;
  height: 3rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor ?? `var(--dark-purple)`};
  border: 0.06rem solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  svg {
    width: 1.1rem;
    height: 0.75rem;
  }

  &:hover {
    border: 0.06rem solid #c4b180 !important;
  }

  @media only screen and (max-width: 767px) {
    bottom: 1.5rem;
    right: 1.5rem;
  }
`
