import React, { useContext, useState } from "react"
import infoIcon from "@images/logo-icon.svg"
import {
  StyledActionButton,
  StyledInfoIcon,
} from "../../common-styles"
import { CookieContext } from "../../CookieContext"
import { withConfig } from "../../withConfig"
import { ToggleButton } from ".."
import {
  CONSENT_BANNER_CLASSNAME,
  CONSENT_BANNER_ID,
  GOOGLE_TAG_FLAGS,
  INFO_ICON_ALTERNATIVE_TEXT,
  CONSENT_BANNER_TITLE,
  CONSENT_BANNER_DESCRIPTION,
  DESKTOP_TOGGLE_STYLES,
  TOGGLE_BUTTONS_TEXTS,
  TOGGLE_BUTTONS_IDS,
  ACTION_BUTTONS_IDS,
  ACTION_BUTTONS_TEXTS,
  CONSENT_MODE_CONFIGURATIONS,
} from "../../constants"
import {
  StyledDesktopCookieBannerWrapper,
  StyledDesktopCookieBannerContainer,
  StyledTextContainer,
  StyledTextContainerTitle,
  StyledTextContainerDescription,
  StyledConsentOptionsContainer,
  StyledActionsButtonContainer,
} from "./styled"
import { ConsentContext } from "../../../../../contexts/ConsentContext"

const DesktopConsentBanner = ({ config }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    CONSENT_MODE_CONFIGURATIONS.DEFAULT
  )
  const { setUserToken } = useContext(CookieContext)
  const { collectConsentData } = useContext(ConsentContext)

  const TOGGLE_BUTTONS = [
    {
      id: TOGGLE_BUTTONS_IDS.NECESSARY,
      isNecessary: true,
      text: TOGGLE_BUTTONS_TEXTS.NECESSARY,
    },
    {
      id: TOGGLE_BUTTONS_IDS.ANALYTICS,
      text: TOGGLE_BUTTONS_TEXTS.ANALYTICS,
      onToggle: () =>
        config.findSelectedOptions(
          setSelectedOptions,
          GOOGLE_TAG_FLAGS.ANALYTICS_STORAGE
        ),
    },
    {
      id: TOGGLE_BUTTONS_IDS.MARKETING,
      text: TOGGLE_BUTTONS_TEXTS.MARKETING,
      onToggle: () =>
        config.findSelectedOptions(
          setSelectedOptions,
          GOOGLE_TAG_FLAGS.AD_STORAGE,
          GOOGLE_TAG_FLAGS.AD_USER_DATA
        ),
    },
    {
      id: TOGGLE_BUTTONS_IDS.PREFERENCES,
      text: TOGGLE_BUTTONS_TEXTS.PREFERENCES,
      onToggle: () =>
        config.findSelectedOptions(
          setSelectedOptions,
          GOOGLE_TAG_FLAGS.PERSONALIZATION_STORAGE
        ),
    },
    {
      id: TOGGLE_BUTTONS_IDS.PARTNERS,
      text: TOGGLE_BUTTONS_TEXTS.PARTNERS,
      onToggle: () =>
        config.findSelectedOptions(
          setSelectedOptions,
          GOOGLE_TAG_FLAGS.AD_PERSONALIZATION
        ),
    },
  ]

  const ACTION_BUTTONS = [
    {
      id: ACTION_BUTTONS_IDS.ACCEPT_ALL,
      text: ACTION_BUTTONS_TEXTS.ACCEPT_ALL,
      isAllowButton: true,
      onClick: () => {
        config.acceptAll()
        collectConsentData("Allow All")
      },
    },
    {
      id: ACTION_BUTTONS_IDS.ALLOW_SELECTION,
      text: ACTION_BUTTONS_TEXTS.ALLOW_SELECTION,
      onClick: () => {
        config.collect(selectedOptions)
        collectConsentData("Allow Selection")
      },
    },
    {
      id: ACTION_BUTTONS_IDS.REJECT_ALL,
      text: ACTION_BUTTONS_TEXTS.REJECT_ALL,
      onClick: () => {
        config.rejectAll()
        collectConsentData("Reject All")
      },
    },
  ]

  return (
    <StyledDesktopCookieBannerWrapper
      id={CONSENT_BANNER_ID}
      className={CONSENT_BANNER_CLASSNAME}
    >
      <StyledDesktopCookieBannerContainer>
        <StyledInfoIcon src={infoIcon} alt={INFO_ICON_ALTERNATIVE_TEXT} />
        <StyledTextContainer>
          <StyledTextContainerTitle>
            {CONSENT_BANNER_TITLE}
          </StyledTextContainerTitle>
          <StyledTextContainerDescription>
            {CONSENT_BANNER_DESCRIPTION}
          </StyledTextContainerDescription>
          <StyledConsentOptionsContainer>
            {TOGGLE_BUTTONS.map(props => (
              <ToggleButton {...props} toggleStyles={DESKTOP_TOGGLE_STYLES} />
            ))}
          </StyledConsentOptionsContainer>
        </StyledTextContainer>
        <StyledActionsButtonContainer>
          {ACTION_BUTTONS.map(({ id, text, isAllowButton, onClick }) => (
            <StyledActionButton
              id={id}
              isAllowButton={isAllowButton}
              onClick={() => {
                onClick()
                setUserToken(true)
              }}
            >
              {text}
            </StyledActionButton>
          ))}
        </StyledActionsButtonContainer>
      </StyledDesktopCookieBannerContainer>
    </StyledDesktopCookieBannerWrapper>
  )
}

export default withConfig(DesktopConsentBanner)
